// taken from Semantic UI (MIT-licensed)
// to be removed again in 2.3 where SUI is available globally

.ui.label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0em 0.14285714em;
  background-color: #E8E8E8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: bold;
  border: 0px solid transparent;
  border-radius: 0.28571429rem;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}

.ui.label:first-child {
  margin-left: 0em;
}

.ui.label:last-child {
  margin-right: 0em;
}

/*-------------------
       Colors
--------------------*/

/*--- Red ---*/

.ui.red.labels .label,
.ui.red.label {
  background-color: #CC0000 !important;
  border-color: #CC0000 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.red.labels .label:hover,
a.ui.red.label:hover {
  background-color: #b30000 !important;
  border-color: #b30000 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.red.corner.label,
.ui.red.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.red.ribbon.label {
  border-color: #990000 !important;
}

/* Basic */

.ui.basic.red.label {
  background: none #FFFFFF !important;
  color: #CC0000 !important;
  border-color: #CC0000 !important;
}

.ui.basic.red.labels a.label:hover,
a.ui.basic.red.label:hover {
  background-color: #FFFFFF !important;
  color: #b30000 !important;
  border-color: #b30000 !important;
}

/*--- Orange ---*/

.ui.orange.labels .label,
.ui.orange.label {
  background-color: #FF8800 !important;
  border-color: #FF8800 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.orange.labels .label:hover,
a.ui.orange.label:hover {
  background-color: #e67a00 !important;
  border-color: #e67a00 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.orange.corner.label,
.ui.orange.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.orange.ribbon.label {
  border-color: #cc6d00 !important;
}

/* Basic */

.ui.basic.orange.label {
  background: none #FFFFFF !important;
  color: #FF8800 !important;
  border-color: #FF8800 !important;
}

.ui.basic.orange.labels a.label:hover,
a.ui.basic.orange.label:hover {
  background-color: #FFFFFF !important;
  color: #e67a00 !important;
  border-color: #e67a00 !important;
}

/*--- Yellow ---*/

.ui.yellow.labels .label,
.ui.yellow.label {
  background-color: #ffcf00 !important;
  border-color: #ffcf00 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.yellow.labels .label:hover,
a.ui.yellow.label:hover {
  background-color: #e6ba00 !important;
  border-color: #e6ba00 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.yellow.corner.label,
.ui.yellow.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.yellow.ribbon.label {
  border-color: #cca600 !important;
}

/* Basic */

.ui.basic.yellow.label {
  background: none #FFFFFF !important;
  color: #ffcf00 !important;
  border-color: #ffcf00 !important;
}

.ui.basic.yellow.labels a.label:hover,
a.ui.basic.yellow.label:hover {
  background-color: #FFFFFF !important;
  color: #e6ba00 !important;
  border-color: #e6ba00 !important;
}

/*--- Olive ---*/

.ui.olive.labels .label,
.ui.olive.label {
  background-color: #B5CC18 !important;
  border-color: #B5CC18 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.olive.labels .label:hover,
a.ui.olive.label:hover {
  background-color: #a7bd0d !important;
  border-color: #a7bd0d !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.olive.corner.label,
.ui.olive.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.olive.ribbon.label {
  border-color: #00953c !important;
}

/* Basic */

.ui.basic.olive.label {
  background: none #FFFFFF !important;
  color: #B5CC18 !important;
  border-color: #B5CC18 !important;
}

.ui.basic.olive.labels a.label:hover,
a.ui.basic.olive.label:hover {
  background-color: #FFFFFF !important;
  color: #a7bd0d !important;
  border-color: #a7bd0d !important;
}

/*--- Green ---*/

.ui.green.labels .label,
.ui.green.label {
  background-color: #00C851 !important;
  border-color: #00C851 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.green.labels .label:hover,
a.ui.green.label:hover {
  background-color: #00af47 !important;
  border-color: #00af47 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.green.corner.label,
.ui.green.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.green.ribbon.label {
  border-color: #00953c !important;
}

/* Basic */

.ui.basic.green.label {
  background: none #FFFFFF !important;
  color: #00C851 !important;
  border-color: #00C851 !important;
}

.ui.basic.green.labels a.label:hover,
a.ui.basic.green.label:hover {
  background-color: #FFFFFF !important;
  color: #00af47 !important;
  border-color: #00af47 !important;
}

/*--- Teal ---*/

.ui.teal.labels .label,
.ui.teal.label {
  background-color: #45ba97 !important;
  border-color: #45ba97 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.teal.labels .label:hover,
a.ui.teal.label:hover {
  background-color: #39ad8a !important;
  border-color: #39ad8a !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.teal.corner.label,
.ui.teal.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.teal.ribbon.label {
  border-color: #379579 !important;
}

/* Basic */

.ui.basic.teal.label {
  background: none #FFFFFF !important;
  color: #45ba97 !important;
  border-color: #45ba97 !important;
}

.ui.basic.teal.labels a.label:hover,
a.ui.basic.teal.label:hover {
  background-color: #FFFFFF !important;
  color: #39ad8a !important;
  border-color: #39ad8a !important;
}

/*--- Blue ---*/

.ui.blue.labels .label,
.ui.blue.label {
  background-color: #0099CC !important;
  border-color: #0099CC !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.blue.labels .label:hover,
a.ui.blue.label:hover {
  background-color: #0086b3 !important;
  border-color: #0086b3 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.blue.corner.label,
.ui.blue.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.blue.ribbon.label {
  border-color: #007399 !important;
}

/* Basic */

.ui.basic.blue.label {
  background: none #FFFFFF !important;
  color: #0099CC !important;
  border-color: #0099CC !important;
}

.ui.basic.blue.labels a.label:hover,
a.ui.basic.blue.label:hover {
  background-color: #FFFFFF !important;
  color: #0086b3 !important;
  border-color: #0086b3 !important;
}

/*--- Violet ---*/

.ui.violet.labels .label,
.ui.violet.label {
  background-color: #6435C9 !important;
  border-color: #6435C9 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.violet.labels .label:hover,
a.ui.violet.label:hover {
  background-color: #5829bb !important;
  border-color: #5829bb !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.violet.corner.label,
.ui.violet.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.violet.ribbon.label {
  border-color: #502aa1 !important;
}

/* Basic */

.ui.basic.violet.label {
  background: none #FFFFFF !important;
  color: #6435C9 !important;
  border-color: #6435C9 !important;
}

.ui.basic.violet.labels a.label:hover,
a.ui.basic.violet.label:hover {
  background-color: #FFFFFF !important;
  color: #5829bb !important;
  border-color: #5829bb !important;
}

/*--- Purple ---*/

.ui.purple.labels .label,
.ui.purple.label {
  background-color: #6e5494 !important;
  border-color: #6e5494 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.purple.labels .label:hover,
a.ui.purple.label:hover {
  background-color: #614887 !important;
  border-color: #614887 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.purple.corner.label,
.ui.purple.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.purple.ribbon.label {
  border-color: #564273 !important;
}

/* Basic */

.ui.basic.purple.label {
  background: none #FFFFFF !important;
  color: #6e5494 !important;
  border-color: #6e5494 !important;
}

.ui.basic.purple.labels a.label:hover,
a.ui.basic.purple.label:hover {
  background-color: #FFFFFF !important;
  color: #614887 !important;
  border-color: #614887 !important;
}

/*--- Pink ---*/

.ui.pink.labels .label,
.ui.pink.label {
  background-color: #E03997 !important;
  border-color: #E03997 !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.pink.labels .label:hover,
a.ui.pink.label:hover {
  background-color: #e61a8d !important;
  border-color: #e61a8d !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.pink.corner.label,
.ui.pink.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.pink.ribbon.label {
  border-color: #c71f7e !important;
}

/* Basic */

.ui.basic.pink.label {
  background: none #FFFFFF !important;
  color: #E03997 !important;
  border-color: #E03997 !important;
}

.ui.basic.pink.labels a.label:hover,
a.ui.basic.pink.label:hover {
  background-color: #FFFFFF !important;
  color: #e61a8d !important;
  border-color: #e61a8d !important;
}

/*--- Brown ---*/

.ui.brown.labels .label,
.ui.brown.label {
  background-color: #A5673F !important;
  border-color: #A5673F !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.brown.labels .label:hover,
a.ui.brown.label:hover {
  background-color: #975b33 !important;
  border-color: #975b33 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.brown.corner.label,
.ui.brown.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.brown.ribbon.label {
  border-color: #805031 !important;
}

/* Basic */

.ui.basic.brown.label {
  background: none #FFFFFF !important;
  color: #A5673F !important;
  border-color: #A5673F !important;
}

.ui.basic.brown.labels a.label:hover,
a.ui.basic.brown.label:hover {
  background-color: #FFFFFF !important;
  color: #975b33 !important;
  border-color: #975b33 !important;
}

/*--- Grey ---*/

.ui.grey.labels .label,
.ui.grey.label {
  background-color: #bbbbbb !important;
  border-color: #bbbbbb !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.grey.labels .label:hover,
a.ui.grey.label:hover {
  background-color: #c8c8c8 !important;
  border-color: #c8c8c8 !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.grey.corner.label,
.ui.grey.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.grey.ribbon.label {
  border-color: #805031 !important;
}

/* Basic */

.ui.basic.grey.label {
  background: none #FFFFFF !important;
  color: #bbbbbb !important;
  border-color: #bbbbbb !important;
}

.ui.basic.grey.labels a.label:hover,
a.ui.basic.grey.label:hover {
  background-color: #FFFFFF !important;
  color: #c8c8c8 !important;
  border-color: #c8c8c8 !important;
}

/*--- Black ---*/

.ui.black.labels .label,
.ui.black.label {
  background-color: #31393c !important;
  border-color: #31393c !important;
  color: #FFFFFF !important;
}

/* Link */

.ui.black.labels .label:hover,
a.ui.black.label:hover {
  background-color: #3c464a !important;
  border-color: #3c464a !important;
  color: #FFFFFF !important;
}

/* Corner */

.ui.black.corner.label,
.ui.black.corner.label:hover {
  background-color: transparent !important;
}

/* Ribbon */

.ui.black.ribbon.label {
  border-color: #805031 !important;
}

/* Basic */

.ui.basic.black.label {
  background: none #FFFFFF !important;
  color: #31393c !important;
  border-color: #31393c !important;
}

.ui.basic.black.labels a.label:hover,
a.ui.basic.black.label:hover {
  background-color: #FFFFFF !important;
  color: #3c464a !important;
  border-color: #3c464a !important;
}

/*-------------------
        Basic
--------------------*/

.ui.basic.label {
  background: none #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Link */

a.ui.basic.label:hover {
  text-decoration: none;
  background: none #FFFFFF;
  color: #1e70bf;
  -webkit-box-shadow: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 1px solid rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Pointing */

.ui.basic.pointing.label:before {
  border-color: inherit;
}

/*-------------------
        Sizes
--------------------*/

.ui.mini.labels .label,
.ui.mini.label {
  font-size: 0.64285714rem;
}

.ui.tiny.labels .label,
.ui.tiny.label {
  font-size: 0.71428571rem;
}

.ui.small.labels .label,
.ui.small.label {
  font-size: 0.78571429rem;
}

.ui.labels .label,
.ui.label {
  font-size: 0.85714286rem;
}

.ui.large.labels .label,
.ui.large.label {
  font-size: 1rem;
}

.ui.big.labels .label,
.ui.big.label {
  font-size: 1.28571429rem;
}

.ui.huge.labels .label,
.ui.huge.label {
  font-size: 1.42857143rem;
}

.ui.massive.labels .label,
.ui.massive.label {
  font-size: 1.71428571rem;
}
